<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qtt }} {{ $tc('checklist.count', qtt).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          v-model="search"
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          type="primary"
          @click="notReady"
          class="float-right"
        >{{ $t('action.add') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom bg-white"
      >

        <div class="mt-4 ml-4">

          <b-row class="w-100">
            <b-col
              cols="12"
              lg="10"
              class="mb-4"
            >
              <h2>
                <font-awesome-icon
                  class="mr-3"
                  icon="fa-light fa-list"
                />Checklists
              </h2>
              <h4>Design checklists to facilitate inspections</h4>

            </b-col>
            <b-col
              cols="12"
              lg="2"
            >
              <b-alert
                show
                variant="warning"
              >Under conception</b-alert>
            </b-col>
          </b-row>

          <!-- <b-img
            :src="require('@/assets/img/MLR00.png')"
            alt="idea"
            fluid
            class="ml-2 ml-md-4 d-none d-sm-block"
          ></b-img> -->
        </div>
      </b-col>
    </b-row>
  </div>
</template>




<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import swal from 'sweetalert2';

const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  components: {
    SideBarMenu,
    IdleTimer,
  },
  data() {
    return {
      qtt: 0,
      search: '',
    };
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    notReady() {
      swalBootsrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
  },
  mounted() {
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
  },
};
</script>