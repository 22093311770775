<template>
  <collapse-transition>
    <div
      class="alert alert-info mobile-warning text-center d-block d-sm-none"
      role="alert"
    >
      <font-awesome-icon
        :icon="['fal', 'mobile-android']"
        class="fa-lg"
      />
      {{ $t('mobileDetected') }}
    </div>
  </collapse-transition>

</template>
<script>
export default {
  data: () => {
    return {};
  },
};
</script>
