<template>
  <b-modal
    id="modal-definition"
    :title="$t('definition.' + mode + 'Definition')"
    button-size="sm"
    @show="onShow"
    @shown="onShown"
  >
    <b-alert
      variant="warning"
      :show="mode === 'edit'"
    >
      <font-awesome-icon
        :icon="['fal', 'exclamation-triangle']"
        class="fa-lg"
      /> {{ $t('building.warningEdit') }}
    </b-alert>
    <b-form
      @submit.prevent="onSubmit"
      @keydown.enter.prevent="onSubmit"
    >
      <b-form-group
        id="input-group-1"
        :label="$t('definition.name')"
        label-for="definitionName"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="6"
        :description="$t('definition.nameDesc')"
      >
        <b-form-input
          id="definitionName"
          v-model="form.name"
          ref="definitionInput"
          type="text"
          @blur="$v.form.name.$touch"
        ></b-form-input>
        <label
          class="error"
          v-if="!$v.form.name.required && $v.form.name.$dirty"
        >{{ $t('validate.requiredFields') }}</label>
        <label
          class="error"
          v-if="!$v.form.name.maxLength && $v.form.name.$dirty"
        >{{ $t('validate.maxLength', { val: '10' }) }}</label>
        <label
          class="error"
          v-if="!$v.form.name.minLength && $v.form.name.$dirty"
        >{{ $t('validate.minLength', { val: '2' }) }}</label>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        :label="$t('definition.description')"
        label-for="definitionDescription"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-input
          id="definitionDescription"
          v-model="form.description"
          type="text"
          @blur="$v.form.description.$touch"
        ></b-form-input>
        <label
          class="error"
          v-if="!$v.form.description.required && $v.form.description.$dirty"
        >{{ $t('validate.required') }}</label>
        <label
          class="error"
          v-if="!$v.form.description.maxLength && $v.form.description.$dirty"
        >{{ $t('validate.maxLength', { val: '500' }) }}</label>
        <label
          class="error"
          v-if="!$v.form.description.minLength && $v.form.description.$dirty"
        >{{ $t('validate.minLength', { val: '1' }) }}</label>
      </b-form-group>
    </b-form>

    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide"
      >{{ $t('action.cancel') }}</b-button>
      <b-button
        type="button"
        @click="onSubmit"
        size="sm"
        variant="primary"
      >{{ $t('action.save') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'modal-definition',
  data() {
    return {
      definitionUuid: '',
      form: {
        name: '',
        description: '',
      },
      mode: '',
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(10),
      },
      description: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(500),
      },
    },
  },
  methods: {
    setMode(mode, data = {}) {
      this.mode = mode;
      if (mode === 'edit') {
        this.definitionUuid = data.uuid;
        this.form.name = data.name;
        this.form.description = data.description;
      } else {
        this.definitionUuid = '';
        this.form.name = '';
        this.form.description = '';
      }
    },
    onShown() {
      this.$nextTick(() => {
        if (this.$refs.definitionInput) {
          this.$refs.definitionInput.focus();
        } else {
          console.error('definitionInput ref is not defined');
        }
      });
    },
    onShow() {
      if (this.mode === 'edit') {
        this.form.name = this.data.name;
        this.form.description = this.data.description;
        this.definitionUuid = this.data.uuid;
      } else {
        this.$v.$reset();
        this.form.name = '';
        this.form.description = '';
      }
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      if (this.mode === 'edit') {
        this.updateDefinition();
      } else {
        this.createDefinition();
      }
    },
    updateDefinition() {
      const projectUuid = this.$store.getters.currentProject.uuid;
      axiosResource
        .put(
          `/admin/definitions/${projectUuid}/${this.definitionUuid}`,
          this.form
        )
        .then((response) => {
          this.$bvModal.hide('modal-definition');
          this.$emit('fetchData');
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    createDefinition() {
      const projectUuid = this.$store.getters.currentProject.uuid;
      axiosResource
        .post(`/admin/definitions/${projectUuid}`, this.form)
        .then((response) => {
          this.$bvModal.hide('modal-definition');
          this.$emit('fetchData');
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>
