<template>
  <b-modal
    id="modal-drawing"
    :title="$t('building.' + mode + 'Dwg')"
    button-size="sm"
    @show="onShow"
  >
    <b-alert
      variant="warning"
      :show="(mode == 'edit') ? true : false"
    >
      <font-awesome-icon
        :icon="['fal', 'exclamation-triangle']"
        class="fa-lg"
      /> {{ $t('building.warningEdit') }}
    </b-alert>
    <b-form
      @submit.prevent="onSubmit"
      enctype="multipart/form-data"
    >
      <b-form-group
        type="text"
        id="input-group-2"
        :label="$t('building.project')"
        label-for="level"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-input
          id="input-horizontal"
          :value="$store.getters.currentProject.ref"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group
        type="text"
        id="input-group-2"
        :label="$t('building.building')"
        label-for="bdgName"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-input
          id="bdgName"
          :value="bdgName + ' (' + bdgRef + ')'"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        type="text"
        :label="$t('building.level')"
        label-for="level"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-input
          id="lvlName"
          v-model="lvlName"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        type="text"
        :label="$t('building.drawing')"
        label-for="drawing"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
        :description="$t('building.pdfRequired')"
      >
        <b-form-file
          type="file"
          name="drawing"
          accept="application/pdf"
          @change="onFileSelected"
          :state="Boolean(form.file)"
          :placeholder="$t('inputFile.placeholder')"
          :drop-placeholder="$t('inputFile.dropPlaceholder')"
        ></b-form-file>
        <!-- <label
          class="error"
          v-if="!$v.form.file.required && $v.form.file.$dirty"
        >{{ $t('validate.requiredFields') }}</label> -->
      </b-form-group>

      <b-progress
        v-if="uploadProgress.show"
        v-model="uploadProgress.value"
        :max="uploadProgress.max"
        show-progress
        animated
      ></b-progress>

    </b-form>

    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide"
      >
        {{ $t('action.cancel') }}
      </b-button>
      <b-button
        type="button"
        @click="onSubmit"
        size="sm"
        variant="primary"
      >
        {{ $t('action.save') }}
      </b-button>
    </template>

  </b-modal>

</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'modal-level',
  data() {
    return {
      type: 'dwg',
      form: {
        file: null,
      },
      levelUuid: '',
      bdgName: '',
      bdgRef: '',
      lvlName: '',
      uploadProgress: {
        value: 0,
        max: 100,
        show: false,
      },
    };
  },
  validations: {
    form: {
      file: {
        required,
      },
    },
  },
  props: {
    node: {
      type: [String, Object],
    },
    mode: String,
    data: Object,
  },
  methods: {
    onShow() {
      console.log('this.$props.data', this.$props.data);

      this.levelUuid = this.data.uuid;

      this.$v.$reset();
      this.form.file = null;
      this.bdgName = this.node.parent.data.label;
      this.bdgRef = this.node.parent.data.ref;
      this.lvlName = this.node.data.label;
      console.log(this.bdgRef);

      // Init progress bar
      this.uploadProgress.show = false;
      this.uploadProgress.value = 0;
    },
    onSubmit() {
      // this.$v.form.$touch();
      // if (this.$v.form.$invalid) return;

      this.createDrawing();
    },
    // updateLevel() {
    //   this.$store.commit('loading', true);
    //   const levelId = this.id;
    //   axiosResource
    //     .put('/admin/buildings/level/' + levelId, this.form)
    //     .then((response) => {
    //       if (!response.data.success) return this.$globalSwal.error();

    //       this.$store.commit('loading', false);

    //       const data = {
    //         name: this.form.name,
    //       };

    //       this.$emit('updateNode', this.$props.node, 'lvl', data);
    //       this.$bvModal.hide('modal-level');
    //     })
    //     .catch((err) => {
    //       this.$store.commit('loading', false);
    //       this.$globalSwal.error(err.response.data.msg);
    //     });
    // },
    onFileSelected(event) {
      this.form.file = event.target.files[0];
    },
    createDrawing() {
      this.$store.commit('loading', true);
      this.uploadProgress.show = true;
      let formData = new FormData();
      formData.append('drawing', this.form.file);
      formData.append('lvlName', this.lvlName);
      console.log(`this.lvlName=${this.lvlName}`);
      const clgStyle = 'color: red;';
      console.log('%c' + 'this.levelUuid', clgStyle);
      console.log(this.levelUuid);

      axiosResource
        .post(`/admin/buildings/drawing/${this.levelUuid}`, formData, {
          onUploadProgress: (uploadEvent) => {
            this.uploadProgress.value = Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            );
            console.log(
              Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + '%'
            );
          },
        })
        .then((response) => {
          this.$store.commit('loading', false);
          const data = {
            uuid: response.data.uuid,
            name: `${response.data.date}, ${response.data.time}`,
            key: response.data.key,
          };
          console.log('DATA from createDrawing', response);

          this.$emit('appendNode', data);
          this.$bvModal.hide('modal-drawing');
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$bvModal.hide('modal-drawing');
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>