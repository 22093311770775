<template>
  <b-modal
    id="modal-building"
    :title="$t('building.'+mode+'Bdg')"
    button-size="sm"
    @show="onShow"
  >
    <b-alert
      variant="warning"
      :show="(mode == 'edit') ? true : false"
    >
      <font-awesome-icon
        :icon="['fal', 'exclamation-triangle']"
        class="fa-lg"
      /> {{ $t('building.warningEdit') }}
    </b-alert>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        type="text"
        id="input-group-2"
        :label="$t('building.project')"
        label-for="level"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-input
          :value="$store.getters.currentProject.ref"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group
        type="text"
        id="input-group-2"
        :label="$t('building.name')"
        label-for="name"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-input
          id="name"
          v-model="form.name"
          type="text"
          v-uppercase
          @blur="$v.form.name.$touch"
        ></b-form-input>
        <label
          class="error"
          v-if="!$v.form.name.required && $v.form.name.$dirty"
        >{{ $t('validate.requiredFields') }}</label>
        <label
          class="error"
          v-if="!$v.form.name.maxLength && $v.form.name.$dirty"
        >{{ $t('validate.maxLength', { val: '30' }) }}</label>
        <label
          class="error"
          v-if="!$v.form.name.minLength && $v.form.name.$dirty"
        >{{ $t('validate.minLength', { val: '3' }) }}</label>
      </b-form-group>

      <b-form-group
        type="text"
        id="input-group-2"
        :label="$t('building.reference')"
        label-for="name"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
        :description="$t('building.descAcronyme')"
      >

        <b-form-input
          id="ref"
          v-model="form.ref"
          v-uppercase
          @blur="$v.form.ref.$touch"
        ></b-form-input>
        <label
          class="error"
          v-if="!$v.form.ref.required && $v.form.ref.$dirty"
        >{{ $t('validate.required') }}</label>
        <label
          class="error"
          v-if="!$v.form.ref.maxLength && $v.form.ref.$dirty"
        >{{ $t('validate.maxLength', { val: '3' }) }}</label>
        <label
          class="error"
          v-if="!$v.form.ref.minLength && $v.form.ref.$dirty"
        >{{ $t('validate.minLength', { val: '3' }) }}</label>
      </b-form-group>
    </b-form>

    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide"
      >
        {{ $t('action.cancel') }}
      </b-button>
      <b-button
        type="button"
        @click="onSubmit"
        size="sm"
        variant="primary"
      >
        {{ $t('action.save') }}
      </b-button>
    </template>

  </b-modal>

</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'modal-building',
  data() {
    return {
      buildingUuid: '',
      form: {
        name: '',
        ref: '',
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30),
      },
      ref: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(3),
      },
    },
  },
  props: {
    node: Object,
    mode: String,
    data: Object,
  },
  methods: {
    onShow() {
      this.buildingUuid = this.data.uuid;
      if (this.mode == 'edit') {
        this.form.name = this.$props.data.label;
        this.form.ref = this.$props.data.ref;
      } else {
        this.$v.$reset();
        this.form.name = '';
        this.form.ref = '';
      }
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      if (this.mode == 'edit') {
        this.updateBuilding();
      } else {
        console.log('createBuilding()');
        this.createBuilding();
      }
    },
    updateBuilding() {
      const clgStyle = 'color: red;';
      console.log('%c' + 'updateBuilding => node:', clgStyle);
      console.log(this.$props.node);
      this.$store.commit('loading', true);
      axiosResource
        .put('/admin/buildings/building/' + this.buildingUuid, this.form)
        .then((response) => {
          if (!response.data.success) return this.$globalSwal.error();

          this.$store.commit('loading', false);
          const data = {
            ref: this.form.ref,
            name: this.form.name,
          };
          this.$emit('updateNode', this.$props.node, 'bdg', data);
          this.$bvModal.hide('modal-building');
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    createBuilding() {
      this.$store.commit('loading', true);
      const projectUuid = this.$store.getters.currentProject.uuid;

      axiosResource
        .post('/admin/buildings/building/' + projectUuid, this.form)
        .then((response) => {
          const clgStyle = 'color: red;';
          console.log('%c' + 'RESPONSE createBuilding()', clgStyle);
          console.log(response);
          this.$store.commit('loading', false);
          const data = {
            uuid: response.data.buildingUuid,
            ref: this.form.ref,
            name: this.form.name,
          };
          console.log(data);
          this.$emit('appendNode', data);
          this.$bvModal.hide('modal-building');
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>
