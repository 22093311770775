<template>
  <b-modal
    id="modal-level"
    :title="$t('building.' + mode + 'Lvl')"
    button-size="sm"
    @show="onShow"
  >
    MODE: {{ this.mode }}
    <b-alert
      variant="warning"
      :show="(mode == 'edit') ? true : false"
    >
      <font-awesome-icon
        :icon="['fal', 'exclamation-triangle']"
        class="fa-lg"
      /> {{ $t('building.warningEdit') }}
    </b-alert>
    <b-form @submit.prevent="onSubmit">
      <input
        type="hidden"
        uuid="buildingUuid"
        v-model="form.buildingUuid"
      />
      <b-form-group
        type="text"
        id="input-group-2"
        :label="$t('building.project')"
        label-for="level"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-input
          id="input-horizontal"
          :value="$store.getters.currentProject.ref"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group
        type="text"
        id="input-group-2"
        :label="$t('building.building')"
        label-for="bdgName"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-input
          id="bdgName"
          :value="bdgName + ' (' + bdgRef + ')'"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        type="text"
        :label="$t('building.level')"
        label-for="level"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <b-form-select
          id="name"
          :selected="form.name"
          v-model="form.name"
          :options="options"
        ></b-form-select>
        <label
          class="error"
          v-if="!$v.form.name.required && $v.form.name.$dirty"
        >{{ $t('validate.requiredFields') }}</label>
      </b-form-group>
    </b-form>

    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide"
      >
        {{ $t('action.cancel') }}
      </b-button>
      <b-button
        type="button"
        @click="onSubmit"
        size="sm"
        variant="primary"
      >
        {{ $t('action.save') }}
      </b-button>
    </template>

  </b-modal>

</template>

<script>
import axiosResource from '@/utils/axiosResource';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'modal-level',
  data() {
    return {
      id: '',
      uuid: '',
      type: 'lvl',
      form: {
        name: null,
        buildingUuid: '',
      },
      bdgName: '',
      bdgRef: '',
      options: [
        { value: null, text: this.$tc('building.selectLevel') },
        { value: '-1', text: '-1' },
        { value: '-2', text: '-2' },
        { value: '-3', text: '-3' },
        { value: '-4', text: '-4' },
        { value: '-5', text: '-5' },
        { value: '00', text: '00' },
        { value: '01', text: '01' },
        { value: '02', text: '02' },
        { value: '03', text: '03' },
        { value: '04', text: '04' },
        { value: '05', text: '05' },
        { value: '06', text: '06' },
        { value: '07', text: '07' },
        { value: '08', text: '08' },
        { value: '09', text: '09' },
        { value: '10', text: '10' },
        { value: '11', text: '11' },
        { value: '12', text: '12' },
        { value: '13', text: '13' },
        { value: '14', text: '14' },
        { value: '15', text: '15' },
        { value: '16', text: '16' },
        { value: '17', text: '17' },
        { value: '18', text: '18' },
        { value: '19', text: '19' },
        { value: '20', text: '20' },
      ],
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  props: {
    node: {
      type: [String, Object],
    },
    mode: String,
    data: Object,
  },
  methods: {
    onShow() {
      const clgStyle = 'color: orange;font-weight: 600; fontw-size: 18px;';
      console.log('%c' + 'onShow', clgStyle);
      console.log('this.$props.data', this.$props.data);

      // If this is a new level and buildingUuid is not yet set, use the node data uuid
      if (this.mode === 'new' && !this.form.buildingUuid) {
        this.uuid = this.node.uuid; // Use the node's uuid as the buildingUuid
        this.form.buildingUuid = this.node.uuid; // Ensure the form has the correct buildingUuid
        console.log('new');
        console.log(`this.uuid`, this.uuid);
        console.log('this.form.buildingUuid', this.form.buildingUuid);
      } else {
        this.uuid = this.data.uuid; // This corresponds to the node uuid (either buildingUuid or levelUuid whether new or edit)
        this.form.buildingUuid = this.data.buildingUuid; // this is the building id used when editing the level name
        console.log('else not new');
        console.log('this.uuid', this.uuid);
        console.log('this.data.buildingUuid', this.data.buildingUuid);
      }

      if (this.mode == 'edit') {
        this.form.name = this.$props.data.label;
        this.form.ref = this.$props.data.ref;
        this.bdgName = this.node.parent.data.label;
        this.bdgRef = this.node.parent.data.ref;
      } else {
        this.$v.$reset();
        this.form.name = null;
        this.bdgName = this.node.data.label;
        this.bdgRef = this.node.data.ref;
      }
    },

    // onShow() {
    //   console.log('this.$props.data', this.$props.data);

    //   this.uuid = this.data.uuid; // This corresponds to the node uuid (either buildingUuid or levelUuid wheter new or edit)

    //   this.form.buildingUuid = this.data.buildingUuid; // this is the building id used when editing the level name

    //   if (this.mode == 'edit') {
    //     this.form.name = this.$props.data.label;
    //     this.form.ref = this.$props.data.ref;
    //     this.bdgName = this.node.parent.data.label;
    //     this.bdgRef = this.node.parent.data.ref;
    //   } else {
    //     this.$v.$reset();
    //     this.form.name = null;
    //     this.bdgName = this.node.data.label;
    //     this.bdgRef = this.node.data.ref;
    //   }
    // },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      if (this.mode == 'edit') {
        this.updateLevel();
      } else {
        this.createLevel();
      }
    },
    updateLevel() {
      this.$store.commit('loading', true);
      const levelUuid = this.uuid;
      axiosResource
        .put('/admin/buildings/level/' + levelUuid, this.form)
        .then((response) => {
          if (!response.data.success) return this.$globalSwal.error();

          this.$store.commit('loading', false);

          const data = {
            name: this.form.name,
          };

          this.$emit('updateNode', this.$props.node, 'lvl', data);
          this.$bvModal.hide('modal-level');
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$globalSwal.error(err.response.data.msg);
        });
    },

    createLevel() {
      this.$store.commit('loading', true);
      const buildingUuid = this.data.uuid;
      const clgStyle = 'color: red;';
      console.log(
        '%c' + 'createLevel() buildingUuid=' + buildingUuid,
        clgStyle
      );
      axiosResource
        .post('/admin/buildings/level/' + buildingUuid, this.form)
        .then((response) => {
          this.$store.commit('loading', false);

          const data = {
            uuid: response.data.levelUuid,
            name: this.form.name,
          };
          this.$emit('appendNode', data);
          this.$bvModal.hide('modal-level');
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>