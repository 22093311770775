<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qtt }} {{ $tc('flag.flag', qtt).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          v-model="search"
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          type="primary"
          @click="createNew"
          class="float-right"
        >{{ $t('action.add') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom bg-white"
      >

        <div class="mt-4">
          <el-table
            :data="searchInTable"
            class="w-100"
            size="large"
            v-loading="loading"
          >
            <el-table-column
              :width="isMobile ? 66 : 100"
              fixed
            >
              <template
                slot="header"
                slot-scope="{}"
              >
              </template>
              <template slot-scope="scope">
                <el-button
                  type="plain"
                  size="mini"
                  @click="handleLookup(scope.$index, scope.row)"
                >
                  <font-awesome-icon
                    icon="fa-light fa-cog"
                    class="d-block d-md-none"
                  />
                  <span class="d-none d-md-block">Manage</span>
                </el-button>
              </template>
            </el-table-column>

            <el-table-column
              label="Flag"
              sortable
              width="400"
            >
              <template slot-scope="scope">
                <span
                  class="flag"
                  :style="'color:' + scope.row.txtColor + '; background-color:' + scope.row.bgColor + '; font-weight:'+scope.row.fontWeight+';font-style:'+scope.row.fontStyle+';'"
                >
                  <font-awesome-icon icon="fa-solid fa-flag" /> {{ scope.row.content }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('explore.tableHeader.by')"
              sortable
              prop="user.acronym4digits"
            >
            </el-table-column>

            <el-table-column
              :label="$t('explore.tableHeader.the')"
              sortable
              prop="dateCreated"
            >
            </el-table-column>

          </el-table>
          <base-pagination
            class="mb-4"
            :total="this.tableData.length"
            :currentPage="this.currentPage"
            :pageSize="this.pageSize"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </b-col>
    </b-row>
    <modal-integrity></modal-integrity>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import ModalIntegrity from '@/components/Modals/ModalIntegrity.vue';
import axiosResource from '@/utils/axiosResource';
import BasePagination from '../../components/BasePagination.vue';
import { isMobileMixin } from '../../mixins/isMobileMixin';

export default {
  mixins: [isMobileMixin],
  components: {
    BasePagination,
    SideBarMenu,
    IdleTimer,
    ModalIntegrity,
  },
  data() {
    return {
      show: false,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      search: '',
      loading: false,
      accessToken: '',
      qtt: 0,
    };
  },
  mounted() {
    this.loading = true;
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
    this.fetchData();
  },
  computed: {
    searchInTable() {
      if (this.search != '') {
        return this.tableData.filter(
          (data) =>
            !this.search ||
            data.content.toLowerCase().includes(this.search.toLowerCase()) ||
            data.user.complete
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            data.user.acronym4digits
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      } else {
        return this.pagedTableData;
      }
    },
    pagedTableData() {
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    handleSizeChange(val) {
      // console.log(`${val} items per page`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(`current page: ${val}`);
      this.currentPage = val;
    },
    handleLookup(index, row) {
      const id = row.id;
      this.$router.push({ name: 'flagcreateedit', params: { id } });
    },
    createNew() {
      this.$router.push({ name: 'flagcreateedit', params: { flagId: 'new' } });
    },
    fetchData() {
      const projectId = this.$store.getters.currentProject.id;
      // INITIAL CALL TO GET ACCESS TOKEN
      axiosResource
        .get(`/admin/flag/${projectId}`)
        .then((response) => {
          this.tableData = response.data.results;
          console.log(response.data.results);
          this.loading = false;
          this.qtt = response.data.results.length;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data.msg == 'error.noIntegrityToken') {
            this.$bvModal.show('modal-integrity');
          } else {
            this.$globalSwal.error(err.response.data.msg);
          }
        });
    },
  },
};
</script>
<style scoped>
.flag {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-right: 12px;
  padding-left: 6px;
  font-size: 14px; 
  display: inline-block; 
  height: 24px;
  border-radius: 3px;
}
</style>
