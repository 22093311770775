<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qtt }} {{ $tc('definition.definition', qtt).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          v-model="search"
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          type="primary"
          @click="createNew"
          class="float-right"
        >{{ $t('action.add') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom bg-white"
      >
        <div class="mt-4">
          <el-table
            :data="searchInTable"
            class="w-100"
            size="large"
            v-loading="loading"
          >
            <el-table-column
              width="180"
              fixed
            >
              <template
                slot="header"
                slot-scope="{}"
              ></template>
              <template slot-scope="scope">
                <el-button
                  type="plain"
                  size="mini"
                  @click="editDefinition(scope.row)"
                  icon="el-icon-edit"
                ></el-button>
                <el-button
                  type="plain"
                  size="mini"
                  @click="handleDelete(scope.row)"
                  icon="el-icon-delete"
                ></el-button>
              </template>
            </el-table-column>

            <el-table-column
              width="220"
              :label="$t('definition.name')"
              prop="name"
              sortable
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              width="400"
              :label="$t('definition.description')"
              prop="description"
              sortable
            ></el-table-column>
            <el-table-column
              :label="$t('definition.by')"
              prop="user.acronym4digits"
              sortable
            ></el-table-column>
            <el-table-column
              :label="$t('definition.date')"
              prop="dateCreated"
              sortable
            ></el-table-column>
          </el-table>
          <base-pagination
            class="mb-4"
            :total="this.tableData.length"
            :currentPage="this.currentPage"
            :pageSize="this.pageSize"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </b-col>
    </b-row>
    <modal-definition
      ref="modalDefinition"
      @fetchData="fetchData"
    ></modal-definition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import BasePagination from '../../components/BasePagination.vue';
import ModalDefinition from '@/components/Modals/ModalDefinition.vue';
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';

const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  components: {
    SideBarMenu,
    IdleTimer,
    BasePagination,
    ModalDefinition,
  },
  data() {
    return {
      show: false,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      search: '',
      loading: false,
      qtt: 0,
    };
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
    createNew() {
      this.$refs.modalDefinition.setMode('new');
      this.$bvModal.show('modal-definition');
    },
    editDefinition(row) {
      this.$refs.modalDefinition.setMode('edit', row);
      this.$bvModal.show('modal-definition');
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleDelete(row) {
      swalBootsrap
        .fire({
          title: this.$t('action.sure'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('action.yesDelete'),
          cancelButtonText: this.$t('action.cancel'),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteDefinition(row.uuid);
          }
        });
    },
    fetchData() {
      const projectUuid = this.$store.getters.currentProject.uuid;
      axiosResource
        .get(`/admin/definitions/${projectUuid}`)
        .then((response) => {
          this.tableData = response.data;
          this.loading = false;
          this.qtt = response.data.length;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data.msg === 'error.noIntegrityToken') {
            this.$bvModal.show('modal-integrity');
          } else {
            this.$globalSwal.error(err.response.data.msg);
          }
        });
    },
    deleteDefinition(definitionUuid) {
      const projectUuid = this.$store.getters.currentProject.uuid;
      axiosResource
        .delete(`/admin/definitions/${projectUuid}/${definitionUuid}`)
        .then((response) => {
          this.fetchData();
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
  computed: {
    searchInTable() {
      if (this.search !== '') {
        return this.tableData.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase()) ||
            data.description.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.pagedTableData;
      }
    },
    pagedTableData() {
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
  mounted() {
    this.loading = true;
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
    this.fetchData();
  },
};
</script>
